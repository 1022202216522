import "element-plus/lib/components/message-box/style/css";
import _ElMessageBox from "element-plus/lib/components/message-box";
import BackgroundVideo from '@/components/backgroundVideo.vue';
import industryInstructions from '@/components/industryInstructions.vue'; // import industryHighlightText from '@/components/industryHighlightText.vue';

import mynFeatures from '@/components/mynFeatures.vue';
import '@/components/industry.css';
export default {
  name: 'Retail',
  components: {
    BackgroundVideo: BackgroundVideo,
    industryInstructions: industryInstructions,
    // industryHighlightText,
    mynFeatures: mynFeatures
  },
  data: function data() {
    return {};
  },
  methods: {
    gasStation: function gasStation() {
      _ElMessageBox.alert(' <div class="center"> <div class="card green"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🛍</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Gas Station</h1> <p>Supporting both bulk and FMC goods, Myneral lends itself perfectly to Gas Stations as both the fuel used and items sold can all be tracked within one platform.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    transport: function transport() {
      _ElMessageBox.alert(' <div class="center"> <div class="card"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🛍</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Transport</h1> <p>See where delivery\'s are in real time with Myneral Labs\' portable tracking solution. Understand innefficiencies and prove conditions such as temperature through our customer facing blockchain certification, <a href="https://myn.link"> myn.link. </a> </p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    custEx: function custEx() {
      _ElMessageBox.alert(' <div class="center"> <div class="card blue"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🛍</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Customer Experience / CSR </h1> <p>Using Myneral\'s revolutionary new \'Myn.link\' technology your customers are able to experience a detailed digital walkthrough of the provenance (history) of the purchased product. This can be leveraged to show the origin of premium products.</p> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    shop: function shop() {
      _ElMessageBox.alert(' <div class="center"> <div class="card black"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🛍</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Shop</h1> <p>Myneral\'s integrated tracking technology makes it easy for stores to track pieces of inventory and prove their provenance all within one pane of glass.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    },
    hq: function hq() {
      _ElMessageBox.alert('<div class="center"> <div class="card green"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🛍</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Real-time Cloud Access</h1> <p>Never get lost in a series of convoluted and cofusing dashboards. With Myneral\'s Cloud Suite, view the status of your supply chain from anywhere with any device!</p>', {
        dangerouslyUseHTMLString: true
      });
    },
    api: function api() {
      _ElMessageBox.alert(' <div class="center"> <div class="card green"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 🛍</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>API</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      });
    }
  }
};